import React, { useEffect, useState } from "react";
import "./ImageGallery.css";
import { getGallery } from "@/features/web/web-actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store";

interface GalleryImage {
  id: number;
  image_url: string;
  alt_tag: string;
}

const ImageGallery: React.FC = () => {
  const { images, loading, error } = useSelector((state: RootState) => state.gallery);
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState<GalleryImage[]>([]);
  const [hoveredImage, setHoveredImage] = useState<number | null>(null);

  useEffect(() => {
    dispatch(getGallery({ categoryId: 1 }))
      .then((res: any) => {
        setData(res.payload.rows || []);
      })
      .catch((err) => console.error("Failed to fetch gallery:", err));
  }, [dispatch]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading gallery. Please try again later.</div>;
  }

  if (data.length === 0) {
    return <div>No data available.</div>;
  }

  return (
    <div id="gallery" className="image-gallery about_us_div">
      <h1 className="blue heading bold pb-3">Gallery</h1>
      <div className="row gx-4">
        {data.map((image) => (
          <div className="col-md-4" key={image.id}>
            <div
              className={`image-wrapper ${hoveredImage === image.id ? "hovered" : ""}`}
              onMouseEnter={() => setHoveredImage(image.id)}
              onMouseLeave={() => setHoveredImage(null)}
              aria-label={`Gallery image ${image.alt_tag}`}
            >
              <img
                src={image.image_url}
                alt={image.alt_tag}
                className="img-responsive"
                loading="lazy"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageGallery;
